import React from 'react'
import './Line.css'

const Line = () => {
  return (
    <div className='Line'>
            <div className='Line__innerContainer'>
                <div className='Line__container'>
                    <div className='Line__left'></div>
                    <div className='Line__center'></div>
                    <div className='Line__right'></div>
                </div>
            </div>
        </div>
  )
}

export default Line